body {
  /* overflow: hidden; Hide scrollbars */
  margin: 20;
}
/* body::-webkit-scrollbar {
  display: none;
} */

.wpwl-form {
  max-width: 100% !important;
  max-height: 60px !important;
  height: 60px !important;
}

.wpwl-apple-pay-button {
  font-size: 14px !important;
  display: block !important;
  width: 100% !important;
  height: 50px !important;
  -webkit-appearance: -apple-pay-button;
  /* -apple-pay-button-type: check-out; */
}

h1 {
  padding: 0px;
  margin: 0px;
  font-size: 28px;
  font-weight: bold;
}

h2 {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 20px;
}
