body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important;

  /* for Firefox */
  height: 100vh;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none !important;
  width: 0px !important; /* remove scrollbar space */
  background: transparent !important; /* for Chrome, Safari, and Opera */
}
